import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardAccountsDetailsIcon from 'mdi-react/CardAccountDetailsIcon';
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';
import PencilCircleIcon from 'mdi-react/PencilCircleIcon';
import { UserDetails, useUserStore } from '../store/userStore';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import EditProfileDialog from './EditProfileDialog';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';
import EmailFastIcon from 'mdi-react/EmailFastIcon';
import MessageDialog from './MessageDialog';
import { useViewStore } from '../store/viewStore';
import Messages from './Messages';
import { v4 as uuidv4 } from 'uuid';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { defaultTheme } from '../theme';
import SearchIcon from 'mdi-react/SearchIcon';
import MainContainer, { SearchBlock } from './MainContainer';
import { MobileActionBar, StyledActionBar } from '../App';
import useFilteredUsers from '../hooks/useFilteredUsers';

import EarthIcon from 'mdi-react/EarthIcon';


const StyledSidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: start;
    padding: 0 2em;
    margin-top: 4.05em;
    padding-top: 2em;
    padding-bottom: 6em;

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        height: auto;
        padding: 0 2em 0 2em;
        justify-content: space-evenly;
        margin-top: 0;
        overflow: scroll;
        margin-bottom: auto;
    }

    img.profilePicture {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: ${props => props.theme.palette.primary.main};
        object-fit: cover;
        object-position: center center;
    }

    h2 {
        color: ${props => props.theme.palette.primary.main};
        font-size: 1.8em;
        max-width: 200px;
        margin: 0;
    }

    p {
        color: ${props => props.theme.palette.secondary.contrastText};
        font-size: 0.8em;
        line-height: 1.5em;
        margin: 0;
        text-align: center;
    }

    h3 {
        color: ${props => props.theme.palette.secondary.contrastText};
        font-weight: 400;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }
`;

const Divider = styled.hr`
    width: 100%;
    border: 2px solid ${props => props.theme.palette.primary.main};
    margin: 1em 0;
`;

const StyledSidebar = styled.div`
    border-radius: ${props => props.theme.borderRadius};
    background: ${props => props.theme.palette.primary.contrastText};
    height: calc(100vh - 6em);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        height: auto;
        min-height: calc(100vh - 4em);
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.lg}px) {
        justify-content: start;
    }
`;

const Bubbles = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
`;

const Bubble = styled.div`
    border-radius: ${props => props.theme.smallBorderRadius};
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    padding: 0.8em 1.6em;
    font-size: 0.8em;
    font-weight: 600;
`;

export const ButtonOutlined = styled.button`
    cursor: pointer;
    border: 2px solid ${props => props.theme.palette.primary.main};
    background: ${props => props.theme.palette.primary.contrastText};
    color: ${props => props.theme.palette.primary.main};
    border-radius: ${props => props.theme.smallBorderRadius};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 0.5em;
    padding: 0.8em 1.6em;
    margin: 2em 0;
`;

const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin: 1.5em 0;
`;

const StyledSidebarHeader = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 0 2em;

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        align-items: center;
    }

    img {
        width: 20%;
        min-width: 100px;
        margin-top: 1em;
        margin-bottom: 0;

        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            width: 50%;
            min-width: 200px;
            margin-top: 1em;
            margin-bottom: 1em;
            object-fit: contain;
        }
    }
`;

const MobileSidebarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    padding: 1em 2em 2em 2em;
    border-bottom: 3px solid ${props => props.theme.palette.primary.main};
    z-index: 999;
`;

const MobileSearchBlock = styled(SearchBlock)`
    border: 2px solid ${props => props.theme.palette.primary.main};
    height: 35px;
    width: 66%;
    margin-top: 1em;

    input {
        padding: 0 10px 0 15px;
    }

    button {
        margin-right: 0;
    }
`;

const Sidebar = () => {

    const { user, setUser, userDetails, setUserDetails, users: userList } = useUserStore();

    const [ profileCompleted, setProfileCompleted ] = useState<boolean>(false);
    const { view, setView, setSidebarView, sidebarView } = useViewStore();

    const { width } = useWindowDimensions();

    const [ filterString, setFilterString ] = useState<string>('');

    const { filteredUsers: users } = useFilteredUsers(filterString);
    
    useEffect(() => {
        if (user) {
            const docRef = doc(db, 'Users', user.uid);
            onSnapshot(docRef, (doc) => setUserDetails(doc.data() as UserDetails))
        }
    }, [setUserDetails, user]);

    useEffect(() => {
        if (userDetails) {
            if (!userDetails.city || !userDetails.country || userDetails.languages.length < 0 || userDetails.markets.length < 0 || !userDetails.name || !userDetails.position) {
                setProfileCompleted(false)
            } else {
                setProfileCompleted(true)
            }
        }
    }, [userDetails]);

    return (
        <StyledSidebar>
            <StyledSidebarHeader>
                {
                    width > defaultTheme.breakpoints.lg &&
                    <img alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
                }
                {
                    width < defaultTheme.breakpoints.lg &&
                    <MobileSidebarHeader>
                        <img alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
                        <MobileSearchBlock>
                            <input type="text" placeholder='Type Name, Country, Language or Market to filter' onClick={() => setSidebarView('GridMobile')} onChange={(e) => setFilterString(e.target.value)} value={filterString} />
                            <button>
                                <SearchIcon />
                            </button>
                        </MobileSearchBlock>
                    </MobileSidebarHeader>
                }
                <Divider />
            </StyledSidebarHeader>
            <StyledSidebarContent>
                {
                    sidebarView === 'Profile' && profileCompleted &&
                    <>
                        <ProfileHeader>
                            <img className="profilePicture" src={userDetails?.profilePic || `${process.env.PUBLIC_URL}/defaultAvatar.png`} alt="Profile" />
                            <h2>{userDetails?.name}</h2>
                        </ProfileHeader>
                        <p>{userDetails?.position}</p>
                        <p>
                            {userDetails?.city && userDetails?.city} {userDetails?.city && userDetails?.country && '/'} {userDetails?.country && userDetails?.country}
                        </p>
                        {
                            userDetails?.languages &&
                            <h3>Spoken Languages</h3>
                        }
                        <Bubbles>
                            {
                                userDetails?.languages &&
                                userDetails?.languages.map(language => {
                                    return <Bubble key={uuidv4()}>{language}</Bubble>
                                })
                            }
                        </Bubbles>
                        {
                            userDetails?.markets &&
                            <h3>Customer Segments</h3>
                        }
                        <Bubbles>
                            {
                                userDetails?.markets &&
                                (userDetails?.markets || ['B2B', 'Education']).map(market => {
                                    return <Bubble key={uuidv4()}>{market}</Bubble>
                                })
                            }
                        </Bubbles>

                        {
                            userDetails?.specialSkills &&
                            <h3>Special Skills / Interests</h3>
                        }
                        <Bubbles>
                            {
                                userDetails?.specialSkills &&
                                (userDetails?.specialSkills || ['B2B', 'Education']).map(specialSkill => {
                                    return <Bubble key={uuidv4()}>{specialSkill}</Bubble>
                                })
                            }
                        </Bubbles>

                        <ButtonOutlined onClick={() => setSidebarView('EditProfile')}>
                            <PencilCircleIcon />
                            Edit Profile
                        </ButtonOutlined>
                    </>
                }
                {
                    sidebarView === 'Profile' && !profileCompleted &&
                    <>
                        <ProfileHeader>
                            <h2 style={{textAlign: 'center'}}>Welcome to Ambassador Connect Platform</h2>
                        </ProfileHeader>
                        <p style={{fontWeight: '600', fontSize: '1em', textAlign: 'center'}}>Create your profile and get in touch with Apple Ambassadors around the world.</p>
                        

                        <ButtonOutlined onClick={() => setSidebarView('EditProfile')}>
                            <PencilCircleIcon />
                            Create Profile
                        </ButtonOutlined>
                    </>
                }
                {
                    sidebarView === 'EditProfile' &&
                    <EditProfileDialog />
                }
                {
                    sidebarView === 'SendMessage' &&
                    <MessageDialog />
                }
                {
                    sidebarView === 'Message' &&
                    <Messages />
                }
                {
                    sidebarView === "GridMobile" &&
                    <MainContainer filteredUserProps={users}/>
                }
                {
                    sidebarView === 'UserList' &&
                    <MainContainer isMapList={true} filteredUserProps={userList}/>
                }
                
            </StyledSidebarContent>
            {
                width > defaultTheme.breakpoints.lg &&
                <StyledActionBar>
                <button onClick={() => setSidebarView('Profile')}>
                    <CardAccountsDetailsIcon />
                    My Profile
                </button>
                {/* <button onClick={() => setSidebarView('Message')}>
                    <EmailFastIcon />
                    Message History
                </button> */}
                {
                    width > defaultTheme.breakpoints.lg &&
                    <>
                    {
                        view === 'Map' ?
                        <button onClick={() => {
                            setView('Grid');
                            setSidebarView('Profile');
                        }}>
                            <ViewDashboardIcon />
                            Grid View
                        </button>
                        :
                        <button onClick={() => setView('Map')}>
                            <EarthIcon />
                            Map View
                        </button>
                    }
                    </>
                }
                {
                    width < defaultTheme.breakpoints.lg &&
                    <button onClick={() => setSidebarView('GridMobile')}>
                        <ViewDashboardIcon />
                        Grid View
                    </button>
                    
                    
                }
                <button onClick={() => {
                    signOut(auth).then(() => setUser(undefined)).catch(error => console.error(error.message))
                }}>
                    <LogoutIcon />
                    Logout
                </button>
            </StyledActionBar>
            }
            {
                width < defaultTheme.breakpoints.lg &&
                <MobileActionBar>
                    <button onClick={() => setSidebarView('Profile')}>
                        <CardAccountsDetailsIcon />
                        My Profile
                    </button>
                    {/* <button onClick={() => setSidebarView('Message')}>
                        <EmailFastIcon />
                        Message History
                    </button> */}
                    {
                        width > defaultTheme.breakpoints.lg &&
                        <button onClick={() => console.log('clicked')}>
                            <ViewDashboardIcon />
                            Grid View
                        </button>
                    }
                    {
                        width < defaultTheme.breakpoints.lg &&
                        <button onClick={() => setSidebarView('GridMobile')}>
                            <ViewDashboardIcon />
                            Ambassadors
                        </button>
                    }
                    <button onClick={() => {
                        signOut(auth).then(() => setUser(undefined)).catch(error => console.error(error.message))
                    }}>
                        <LogoutIcon />
                        Logout
                    </button>
                </MobileActionBar>
            }
        </StyledSidebar>
    )
}

export default Sidebar;