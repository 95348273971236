import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { CSSProperties, FC, Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { db, storage } from '../firebase';
import { useUserStore } from '../store/userStore';
import { v4 as uuidv4 } from 'uuid';
import { StyledFormControl, StyledInput } from './SignUpDialog';
import { ButtonOutlined } from './Sidebar';
import { AuthErrorCodes } from 'firebase/auth';
import PencilCircleIcon from 'mdi-react/PencilCircleIcon';
import { useViewStore } from '../store/viewStore';
import LoadingSpinner from './LoadingSpinner';
import Select, { StylesConfig } from 'react-select';

interface Error {
    field: string;
    errorMessage: string;
}

interface StyledFormProps {
    ref: any;
}

const StyledForm = styled.form<StyledFormProps>`
    > div {
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: center;
        margin-bottom: 1em;
    }

    h3 {
        font-weight: bold;
        color: ${props => props.theme.palette.primary.main};
        text-align:center;
        margin-top: 0;
        margin-bottom: 1em;
    }
    small {
        color: black;
        font-size: 0.7em;
        line-height: 0.5em !important;
    }
`;

const StyledInputControl = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0.5em;

    input {
        width: 100%;
        box-sizing: border-box;
    }

    &.hasError {
        input {
            border-color: ${props => props.theme.palette.common.red};
        }
        label {
            color: ${props => props.theme.palette.common.red};
        }
    }

    .country-select {
        border: 2px solid ${props => props.theme.palette.primary.main};
        border-radius: 8px;
        margin-bottom: 0.8em;

        > div {
            border: none;
            border-radius: 8px;
            font-size: 0.8em;
            min-height: 30px;
            font-weight: 500;
            color: #000000;

            > div
            {
                > div {
                    padding: 0 8px;
                }
            }
        }
    }
`;

const StyledLabel = styled.label`
    position: absolute;
    top: -1em;
    right: 0.8em;
    color: black;
    background: white;
    font-size: 0.7em;
    padding: 0.2em 0.5em;
    font-weight: bold;

    &.contry-label {
        z-index: 99999
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 1em;
`;

const StyledError = styled.p`
    color: ${props => props.theme.palette.common.red} !important;
    font-weight: 600;
    width: 100%;
    margin-top: -0.8em !important;
    position: absolute;
`;

const StyledEditablePic = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1em;

    .profilePicture {
        width: 100px;
        height: 100px;
        cursor: pointer;
    }

    .profilePicInput {
        display:none;
    }

    .editPicIcon {
        position: absolute;
        bottom: 1em;
        right: calc(50% - 3em);
        cursor: pointer;
    }
`;

const EditProfileDialog: FC = (props) => {

    const { user, userDetails } = useUserStore();

    const [ profilePic, setProfilePic ] = useState<string>(userDetails?.profilePic || '');
    const [ firstName, setFirstName ] = useState<string>(userDetails?.name.split(' ')[0] || '');
    const [ lastName, setLastName] = useState<string>(userDetails?.name.split(' ')[1] || '');
    const [ position, setPosition ] = useState<string>(userDetails?.position || '');
    const [ city, setCity ] = useState<string>(userDetails?.city || '');
    const [ country, setCountry ] = useState<string>(userDetails?.country || '');
    const [ languages, setLanguages ] = useState<string>(userDetails?.languages?.join(', ') || '');
    const [ markets, setMarkets ] = useState<string>(userDetails?.markets?.join(', ') || '');
    const [ specialSkills, setSpecialSkills ] = useState<string>(userDetails?.specialSkills?.join(', ') || '');
    const [ errors, setErrors ] = useState<Error[]>([]);
    const [ displayErrors, setDisplayErrors ] = useState<boolean>(false);
    const [ uploadingImage, setUploadingImage ] = useState<boolean>(false);
    const [ countries, setCountries ] = useState<any>();
    const [ userCountry, setUserCountry ] = useState<any>();

    const { setSidebarView } = useViewStore();
    const formElement = useRef();

    const saveProfile = () => {
        if (errors.length === 0) {
            setDoc(doc(db, 'Users', user!.uid), {
                name: `${firstName} ${lastName}`,
                position: position,
                city: city,
                country: country,
                languages: languages!.split(', '),
                markets: markets!.split(', '),
                specialSkills: specialSkills!.split(', '),
                profilePic: profilePic,
                email: user?.email,
                admin: user?.email === 'demo@user.com' ? true : false
            })
            return setSidebarView('Profile');
        } else {
            setDisplayErrors(true);
        }
    }

    const uploadProfilePic = (file: any, uid: string) => {
        setUploadingImage(true);
        const storageRef = ref(storage, `thumb_${uid}`);
        uploadBytes(storageRef, file)
        .then((snapshot => {
            getDownloadURL(storageRef).then(url => {
                updateDoc(doc(db, 'Users', user!.uid), {
                    profilePic: url
                })
                setProfilePic(url)
                setUploadingImage(false);
            })
        }))
        .catch(error => console.error(error.message))
    }

    const validateForm = () => {
        if (formElement && formElement.current) {
            const currentFormElement: any = formElement.current;
            const inputs = currentFormElement.querySelectorAll('input');
            for (let input of inputs) {
                if (input.dataset.required) {
                    if (input.value.length === 0) {
                        const errorsCopy = errors;
                        const filteredErrorsCopy = errorsCopy.filter(error => error.field === input.id);
                        if (filteredErrorsCopy.length === 0) {
                            console.log(errorsCopy);
                            errorsCopy.push({
                                field: input.id,
                                errorMessage: `required`
                            })
                            return setErrors(errorsCopy);
                        }
                    } else if (input.value.length > 0) {
                        const errorsCopy = errors;
                        const filteredErrorsCopy = errorsCopy.filter(error => error.field === input.id);
                        if (filteredErrorsCopy.length > 0) {
                            const filteredErrorsCopy = errorsCopy.filter(error => error.field !== input.id);
                            return setErrors(filteredErrorsCopy);
                        }
                    }
                }
            }
        }
    }

    const getCountries = () => {
        fetch(`${process.env.PUBLIC_URL}/countries.json`
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then((response) => {
            return response.json();
          })
          .then((myJson) => {
            const sortCountries = myJson.sort((a: any, b: any) => {
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
            });
            setCountries(sortCountries);
            const currentCountry = myJson.filter((item: any) => item.label === userDetails?.country);
            if (currentCountry.length === 0) {
                setUserCountry(myJson[0]);    
            } else {
                setUserCountry(currentCountry[0]);
            }
          });
      }

    useEffect(() => {
        validateForm();
        getCountries();
    }, [firstName, lastName, position, city, country, languages, markets, specialSkills]);

    const profilePicRef = useRef<any>();

    const colourStyles: StylesConfig<any> = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
            ...styles,
            backgroundColor: '#ffffff',
            color: '#000000',
            ':active': {
                ...styles[':active'],
                backgroundColor: '#D546DB',
                color: '#ffffff'
            },
            };
        }
    };

    const handleInputChange = (newValue: any) => {
        setCountry(newValue.label);
        return newValue;
    };

    return (
        <>
        {
            errors.length > 0 ?
            console.log('Validation Errors:', errors)
            : console.log('no errors')
        }
        <StyledForm ref={formElement}>
            <h3>Edit Profile</h3>
                <StyledEditablePic>
                    <StyledInput ref={profilePicRef} className="profilePicInput" type="file" accept="image/jpg/jpeg/png" onChange={(e) => { uploadProfilePic(e.target.files![0], user!.uid) }} />
                    {
                        !uploadingImage &&
                        <img className="profilePicture" src={userDetails?.profilePic || `${process.env.PUBLIC_URL}/defaultAvatar.png`} alt="Profile" onClick={() => {
                            profilePicRef.current.click();
                        }}/>
                    }
                    {
                        uploadingImage &&
                        <LoadingSpinner size={100} />
                    }
                    {
                        !uploadingImage &&
                        <PencilCircleIcon className="editPicIcon" style={{fill: 'black'}} onClick={() => {
                            profilePicRef.current.click();
                        }}/>
                    }
                </StyledEditablePic>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'firstName').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>First Name</StyledLabel>
                    <StyledInput type="text" id="firstName" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setFirstName(e.target.value)}
                    } placeholder="First Name" value={firstName} data-required/>
                    {
                        displayErrors && errors.filter(error => error.field === 'firstName').length > 0 &&
                        <StyledError>Required</StyledError>
                    }
                </StyledInputControl>
                <StyledInputControl className={errors.filter(error => error.field === 'lastName').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>Last Name</StyledLabel>
                    <StyledInput type="text" id="lastName" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setLastName(e.target.value)
                    }}  placeholder="Last Name" value={lastName} data-required/>
                    {
                        displayErrors && errors.filter(error => error.field === 'lastName').length > 0 &&
                        <StyledError>Required</StyledError>
                    }
                </StyledInputControl>
            </div>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'position').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>Position</StyledLabel>
                    <StyledInput type="text" id="position" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setPosition(e.target.value)}
                    } placeholder="Position" value={position} data-required/>
                    {
                        displayErrors && errors.filter(error => error.field === 'position').length > 0 &&
                        <StyledError>Required</StyledError>
                    }
                </StyledInputControl>
            </div>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'city').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>City</StyledLabel>
                    <StyledInput type="text" id="city" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setCity(e.target.value)}
                     }
                    placeholder="City" value={city} data-required/>
                    {
                        displayErrors && errors.filter(error => error.field === 'city').length > 0 &&
                        <StyledError>Required</StyledError>
                    }
                </StyledInputControl>
                <StyledInputControl className={errors.filter(error => error.field === 'country').length > 0 ? 'hasError' : ''}>
                    <StyledLabel className='contry-label'>Country</StyledLabel>
                    {
                        countries && userCountry &&
                        <Select className='country-select' styles={colourStyles} options={countries} defaultValue={userCountry} id="country" onChange={handleInputChange}
                        placeholder="Country" data-required />
                    }
                    
                    {
                        displayErrors && errors.filter(error => error.field === 'country').length > 0 &&
                        <StyledError>Required</StyledError>
                    }
                </StyledInputControl>
            </div>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'languages').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>Languages</StyledLabel>
                    <StyledInput type="text" id="languages" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setLanguages(e.target.value)} 
                    }
                    placeholder="Languages (e.g. English, Spanish)" value={languages} data-required/>
                    {
                            displayErrors && errors.filter(error => error.field === 'languages').length > 0 &&
                            <StyledError>Required</StyledError>
                        }
                </StyledInputControl>
            </div>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'markets').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>Customer Segments</StyledLabel>
                    <StyledInput type="text" id="markets" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setMarkets(e.target.value)} 
                    }
                    placeholder="Markets (e.g. Small business, Major accounts, ...)" value={markets} data-required/>
                    {
                            displayErrors && errors.filter(error => error.field === 'markets').length > 0 &&
                            <StyledError>Required</StyledError>
                        }
                </StyledInputControl>
            </div>
            <div>
                <StyledInputControl className={errors.filter(error => error.field === 'specialSkills').length > 0 ? 'hasError' : ''}>
                    <StyledLabel>Specialized skills / interests</StyledLabel>
                    <StyledInput type="text" id="specialSkills" onChange={(e) => {
                        e.target.value.length === 0 && validateForm()
                        setSpecialSkills(e.target.value)} 
                    }
                    placeholder="Special skills / interests (e.g. Banking, Security, 5G, ...)" value={specialSkills} data-required/>
                    {
                            displayErrors && errors.filter(error => error.field === 'specialSkills').length > 0 &&
                            <StyledError>Required</StyledError>
                        }
                </StyledInputControl>
            </div>
            <small>Apple will collect the personal data you provide in this form to create your profile on Ambassadors Connect. By submitting this form you acknowledge that your personal data on this form may be shared with or accessed by the other users on the Ambassadors Connect Platform. If you would like to receive further information on what Apple collects or to delete your profile, please email the Ambassadors Connect program team at support@ambassadorsconnect.com.  In the e-mail subject line, copy and paste the following: “Ambassadors Connect: Personal Data Request”.  In the text of the e-mail please provide an explanation of your request along with your name, your company name, and your work email.  Within 24-48 hours we will respond to your request.  At all times, information collected by Apple will be treated in accordance with Apple’s Privacy Policy, which can be found at www.apple.com/privacy.</small>
        </StyledForm>
        <ButtonGroup>
            <ButtonOutlined onClick={() => setSidebarView('Profile')}>Cancel</ButtonOutlined>
            <ButtonOutlined onClick={() => saveProfile()}>Save</ButtonOutlined>
        </ButtonGroup>
        </>
    )
}

export default EditProfileDialog;