import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { UserDetails, useUserStore } from "../store/userStore";

const useFilteredUsers = (str?: string) => {
    const { user } = useUserStore();
    const [users, setUsers] = useState<any[]>();
    const [ filterString, setFilter ] = useState<string>('');

    const isComplete = (user: UserDetails): boolean => {
        if (user.name && user.position && user.city && user.country && user.languages && user.markets) {
            return true;
        } else {
            return false;
        }
    }
    
    const getUsers = async(str?: string) => {
        const querySnapshot = await getDocs(query(collection(db, 'Users'), where("admin", "==", false)));
        const usersResult: any[] = [{usersRow: [], type: 'first-row'}];
        let count = 0;
        let row = 0;
        setUsers(usersResult);
        querySnapshot.forEach((doc: any) => {
            if (!doc.admin && isComplete(doc.data())) {
                if (count === 0) {
                    if (usersResult.length > 0 && usersResult[row]['usersRow'].length === 2) {
                        usersResult.push({usersRow: [], type: 'first-row'});
                        if (row) {
                            row++;
                        }
                    }
                } else if (count === 3) {
                    if (usersResult.length > 0 && usersResult[row]['usersRow'].length === 3) {
                        usersResult.push({usersRow: [], type: 'second-row'});
                        row++;
                    }
                } else if (count === 5) {
                    if (usersResult.length > 0 && usersResult[row]['usersRow'].length === 2) {
                        usersResult.push({usersRow: [], type: 'third-row'});
                        row++;
                    }
                }
                if (count < 7) {
                    const userItem: UserDetails = doc.data();
                    if (str) {
                        if (filterUsers(str, userItem)) {
                            count++;
                            usersResult[row].usersRow.push(userItem);
                        }
                    } else {
                        count++;
                        usersResult[row].usersRow.push(userItem);
                    }
                } else {
                    count = 0;
                }
            }
        });
        setUsers(usersResult);
    }

    useEffect(() => {
        getUsers(str);
    }, [str]);

    const search = (e: any) => {
        setFilter(e.target.value);
        getUsers(e.target.value);
    }

    const filterUsers = (str: string, data: UserDetails): boolean | undefined => {
        return data.name?.toLowerCase().includes(str.toLowerCase()) || 
                data.city?.toLowerCase().includes(str.toLowerCase()) || 
                data.country?.toLowerCase().includes(str.toLowerCase()) ||
                data.position?.toLowerCase().includes(str.toLowerCase()) ||
                (data.languages && data.languages.filter((lang: string) => lang.toLowerCase().includes(str.toLowerCase())).length > 0) ||
                (data.markets && data.markets.filter((market: string) => market.toLowerCase().includes(str.toLowerCase())).length > 0) ||
                (data.specialSkills && data.specialSkills.filter((specialSkill: string) => specialSkill.toLowerCase().includes(str.toLowerCase())).length > 0);
    }

    return { filteredUsers: users, filterString };
}

export default useFilteredUsers;