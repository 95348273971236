import { getDocs, collection, query, where, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { db } from '../firebase';
import { MessageObject, useUserStore } from '../store/userStore';
import MessageCard from './MessageCard';
import { v4 as uuidv4 } from 'uuid';
import { useViewStore } from '../store/viewStore';

const Messages = () => {

    const { user, fromMessages, toMessages } = useUserStore();

    const [ messagesTo, setMessagesTo ] = useState<MessageObject[]>([]);
    const [ messagesFrom, setMessagesFrom ] = useState<MessageObject[]>([]);


    useEffect(() => {
        user &&
            console.log(fromMessages);
    }, [fromMessages]);

    return (
        <>
            <h2 style={{width: '100%', textAlign: 'center', marginBottom: '1.5em'}}>Message History</h2>
            {
                fromMessages && fromMessages.length > 0 &&
                fromMessages.map(message => <MessageCard key={uuidv4()} type='to' message={message} />)
            }
            {
                toMessages && toMessages.length > 0 &&
                toMessages.map(message => <MessageCard key={uuidv4()} type='from' message={message} />)
            }
        </>
    )
}

export default Messages;