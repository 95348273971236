import create from 'zustand';
import { UserDetails } from './userStore';

type View = 'Profile' | 'Message' | 'EditProfile' | 'SendMessage' | 'GridMobile' | 'UserList' | 'Grid' | 'Map';

interface ViewState {
    view: View;
    sidebarView: View;
    setView(view: string): void;
    setSidebarView(sidebarView: string): void;
    messageRecipient?: UserDetails;
    setMessageRecipient(recipient: UserDetails): void;
    setUserList(userList: UserDetails[]): void;
    userList?: UserDetails[];
}

export const useViewStore = create<ViewState>(set => ({
    view: 'Grid',
    sidebarView: 'Profile',
    setView: (view: View) => set(state => ({ ...state, view})),
    messsageRecipient: undefined,
    setMessageRecipient: (recipient: UserDetails) => set(state => ({ ...state, messageRecipient: recipient })),
    setUserList: (userList: UserDetails[]) => set(state => ({ ...state, userList })),
    userList: undefined,
    setSidebarView: (sidebarView: View) => set(state => ({ ...state, sidebarView}))
    }),
);