import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import React, { FC, useRef, useState } from 'react';
import { auth, db } from '../firebase';
import { useUserStore } from '../store/userStore';
import Logo from '../../public/logo.svg';
import styled from 'styled-components';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { defaultTheme } from '../theme';
import { MobileActionBar, StyledActionBar } from '../App';
import { ButtonOutlined } from './Sidebar';
import LoadingSpinner from './LoadingSpinner';
// import { initializeApp } from 'firebase/app';
// import { getFunctions, httpsCallable } from 'firebase/functions';

interface StyledSignUpDialogProps {
    ref: any
}

const StyledSignUpDialog = styled.div<StyledSignUpDialogProps>`
    background: ${props => props.theme.palette.primary.contrastText};
    border-radius: ${props => props.theme.borderRadius};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 40em;
    padding-top: 1em;
    margin: 1em;

    div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2em;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            margin-bottom: 0;
        }
    }

    p, a {
        color: black;
        font-size: 0.8em;
        text-decoration: underline;
    }

    img {
        width: 50%;
        margin-bottom: 2em;
    }

    .reset-pass {
        cursor: pointer;
    }
`;

interface StyledInputProps {
    ref?: any
}

export const StyledInput = styled.input<StyledInputProps>`
    border: 2px solid ${props => props.theme.palette.primary.main};
    border-radius: 8px;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    width: 74%;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        width: 30%;
    }
`;

export const StyledFormControl = styled.div`
    width: 80%;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        width: 36%;
    }
    display: grid;
    grid-template-columns: 1fr 30px;
    margin: 0;
    padding: 0;
    align-items: center;
    margin-bottom: 1em;

    input {
        width: calc(100% - 1em);
        margin: 0;
        padding-right: 2em;
    }

    svg {
        fill: black;
        align-self: center;
        height: 1.2em;
    }
`;

const StyledErrorMessage = styled.span`
    color: ${props => props.theme.palette.common.red} !important;
    font-weight: bold;
    padding: 0.5em 0 1em 0;
    font-size: 0.8em;
`;

const StyledSuccessMessage = styled.span`
    color: green;
    font-weight: bold;
    padding: 0.5em 0 1em 0;
    font-size: 0.8em;
`;

const SignUpDialog: FC = (props) => {
    const { user, setUser } = useUserStore();
    const [ email, setEmail ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ showPassword, setShowPassword ] = useState<boolean>(false);
    const [ focus, setFocus ] = useState<string>('email');
    const [ error, setError ] = useState<string>('');
    const [ signUp, setSignUp ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ loggingIn, setLoggingIn ] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<boolean>(false);


    const { width } = useWindowDimensions();

    const formElement = useRef();

    // const functions = getFunctions();

    // const sendResetEmail = httpsCallable(functions, 'sendEmail');
    // sendResetEmail()
    // .then((result: any) => {
    //     console.log({result});
    // });

    const createUser = () => {
        setLoading(true);
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            setUser(userCredential.user);
            setDoc(doc(db, "Users", userCredential.user.uid), {
                name: '',
                admin: false
            })
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            setError(error.code)
            setTimeout(() => {
                setError('')
            }, 3500)
        })
    }

    const resetPass = () => {
        if (!successMessage) {
            sendPasswordResetEmail(auth, email)
            .then(() => {
            setSuccessMessage(true);
            setTimeout(() => {
                setSuccessMessage(false);
            }, 5000);
        })
        .catch(error => console.error(error.message));
        }
        
    }

    return (
        <>
        {
            loading &&
            <LoadingSpinner color={width > defaultTheme.breakpoints.lg ? 'white' : defaultTheme.palette.primary.main} size={50} />
        }
        {
            !loading &&
            <StyledSignUpDialog ref={formElement}>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} />
                    <StyledInput type="email p" onChange={(e) => setEmail(e.target.value)} placeholder="E-Mail" autoFocus={focus === 'email'} />
                    <StyledFormControl>
                        <StyledInput className='pass-input' type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)} placeholder="Password" autoFocus={focus === 'pw'} />
                        {
                            !showPassword 
                            ? <EyeOutlineIcon onClick={() => setShowPassword(!showPassword)} />
                            : <EyeOffOutlineIcon onClick={() => setShowPassword(!showPassword)} />
                        }
                    </StyledFormControl>
                    {
                        signUp &&
                        <ButtonOutlined style={{margin: 0, marginBottom: '0.5em'}} onClick={() => createUser()}>
                            Create User
                        </ButtonOutlined>
                    }
                    {
                        error &&
                        <StyledErrorMessage>
                            {error === 'auth/user-not-found' && 'No user found, click "Sign Up" to open a new profile'}
                            {error === 'auth/invalid-email' && 'Please enter a valid email address'}
                            {error === 'auth/wrong-password' && 'Wrong password'}
                            {error === 'auth/internal-error' && 'An error occured, please check your input'}
                            {error === 'auth/weak-password' && 'Please select a stronger password with at least 6 characters'}
                            {error === 'auth/email-already-in-use' && 'This email is already associated with a user'}
                        </StyledErrorMessage>
                    }
                    {
                        !signUp &&
                        <div>
                            <div>
                                <a className='reset-pass' onClick={resetPass}>
                                    Reset Password
                                </a>
                            </div>
                            
                            {
                                successMessage &&
                                <StyledSuccessMessage>Please open your email and Follow this link to reset your password</StyledSuccessMessage>
                            }
                            
                        </div>
                        
                    }
                </div>

                {
                    width > defaultTheme.breakpoints.lg &&
                    <StyledActionBar>

                    <button type="submit" onClick={(e) => {
                        // e.preventDefault();
                        setSignUp(!signUp);
                        createUser();
                    }}><AccountPlusIcon />Sign up</button>

                    <button type="submit" onClick={(e) => {
                        setLoading(true);
                        signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                            setLoading(false);
                            setUser(userCredential.user)
                        })
                        .catch(error => {
                            setError(error.code);
                            setLoading(false);
                            setTimeout(() => {
                                setError('')
                            }, 3000)
                        })
                    }}><AccountIcon />Login</button>

                </StyledActionBar>
                }

                {
                    width < defaultTheme.breakpoints.lg &&
                    <MobileActionBar>

                    <button onClick={(e) => {
                        // e.preventDefault();
                        // setSignUp(!signUp);
                        createUser();
                    }}><AccountPlusIcon />Sign up</button>

                    <button type="submit" onClick={(e) => {
                        setLoading(true);
                        signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                            setUser(userCredential.user)
                            setLoading(false);
                        })
                        .catch(error => {
                            setError(error.code)
                            setLoading(false);
                            setTimeout(() => {
                                setError('')
                            }, 3000)
                        })
                    }}><AccountIcon />Login</button>

                </MobileActionBar>
                }    
            </StyledSignUpDialog>
        }
        </>
    )
}

export default SignUpDialog;