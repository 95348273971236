import React, { FC } from 'react';
import styled from 'styled-components';

interface LoadingSpinnerProps {
    color?: string;
    size?: number;
}

const StyledLoadingSpinner = styled.div<LoadingSpinnerProps>`

    display: inline-block;
    position: relative;
    margin-left: 00.5em;
    width: ${props => props.size || 20}px;
    height: ${props => props.size || 20}px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${props => props.size || 20}px;
        height: ${props => props.size || 20}px;
        margin: 0px;
        border: 3px solid white;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${props => props.color || props.theme.palette.primary.main} transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

`;

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
    const { color, size } = props;
    return (
        <StyledLoadingSpinner color={color} size={size}>
            <div></div><div></div><div></div><div></div>
        </StyledLoadingSpinner>
    )
}

export default LoadingSpinner;