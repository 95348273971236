import { User } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { useState } from 'react';
import create from 'zustand';
import { auth, db } from '../firebase';


interface UserState {
    user: User | undefined;
    setUser(user: User | undefined): void;
    userDetails?: UserDetails;
    setUserDetails(details: UserDetails): void;
    fromMessages?: MessageObject[];
    toMessages?: MessageObject[];
    userMessages?: Message[];
    users: UserDetails[] | undefined;
    setUsers(users: UserDetails[] | undefined): void;
}

export interface UserDetails {
    name: string;
    position: string;
    city: string;
    country: string;
    languages: string[];
    markets: string[];
    specialSkills: string[];
    profilePic: string;
    email?: string;
    admin?: boolean;
}

export interface MessageObject {
    from: string;
    to: string[];
    fromName: string;
    message: Message;
}

interface Message {
    subject: string;
    text: string;
}

export const userDetails = auth.currentUser ? onSnapshot(doc(db, 'Users', auth.currentUser.uid), (doc) => {
    doc.data()
}) : undefined;


// export const fromMessages = auth.currentUser ? onSnapshot(query(collection(db, "Mails"), where("from", "==", auth.currentUser.email)), (querySnapshot) => {
//     return querySnapshot
// }) : undefined;
let fromMessages: MessageObject[] = []

if (auth.currentUser) {
    const fromMessageSnapshot = onSnapshot(query(collection(db, "Mails"), where("from", "==", auth.currentUser?.email)), (docs) => {
        docs.forEach(doc => 
            fromMessages.push(doc.data() as MessageObject)
        )
    });
}

let toMessages: MessageObject[] = []

if (auth.currentUser) {
    const toMessageSnapshot = onSnapshot(query(collection(db, "Mails"), where("to", "array-contains", auth.currentUser?.email)), (docs) => {
        docs.forEach(doc => 
            toMessages.push(doc.data() as MessageObject)
            )
        });
}

export const useUserStore = create<UserState>(set => ({
        user: undefined,
        setUser: (user: User) => set(state => ({user: user})),
        setUserDetails: (details: UserDetails) => set(state => ({userDetails: details })),
        userDetails: undefined,
        fromMessages: fromMessages,
        toMessages: toMessages,
        users: undefined,
        setUsers: (users: UserDetails[]) => set(state => ({users}))
    })
)


