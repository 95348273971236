import React, { useState, FC, useEffect } from 'react';
import styled from 'styled-components';
import { UserCard } from './UserCard';
import { v4 as uuidv4 } from 'uuid';

const StyledUsersRow = styled.div`
    /* overflow: hidden; */
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    gap: 2em;
    width: 100%;
    height: auto;
    @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
        overflow: hidden;
    }
    min-height: 200px;
    .bubbles-block {
        .buttons {
            display: none;
        }
    }
    &.second-row {
        grid-template-columns: 1fr;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            grid-template-columns: 2fr 3fr;
        }
            
        > div {
            &:last-child {
                .bubbles-block {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    @media screen and (min-width > ${props => props.theme.breakpoints.lg}px) {
                        flex-direction: row;
                    }
        
                    > div {
                        width: 100%;
                        @media screen and (min-width > ${props => props.theme.breakpoints.lg}px) {
                            width: calc(100% - 80px);
                        }
                        align-self: center;
                        
                        &.buttons {
                            display: flex;
                            flex-direction: column;
                            width: 65px;
                            justify-content: start;
                            align-items: start;
                            gap: 10px;
                            margin-top: 0;
                            align-self: center;
                        }
                    }
                }

                > div {
                    > .buttons {
                        display: none;
                    }
                }
            }
        }
        
    }
    &.third-row {
        grid-template-columns: 1fr;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            grid-template-columns:3fr 2fr;
        }

        > div {
            &:first-child {
                .bubbles-block {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    @media screen and (min-width > ${props => props.theme.breakpoints.lg}px) {
                        flex-direction: row;
                    };
                    
                    > div {
                        width: 100%;

                        align-self: center;
                        
                        &.buttons {
                            flex-direction: column;
                            width: 65px;
                            justify-content: center;
                            align-items: center;
                            @media screen and (min-width > ${props => props.theme.breakpoints.lg}px) {
                                justify-content: start;
                                align-items: start;  
                            }
                            gap: 10px;
                            margin-top: 0;
                            display: block;
                        }
                    }
                }

                > div {
                    > .buttons {
                        display: none;
                    }
                }
            }
        }
    }
    &.first-row {
        grid-template-columns: 1fr;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &.third-row {
        grid-template-columns: 1fr;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            grid-template-columns: 3fr 2fr;
        }

        > div {
            &:first-child {
                .bubbles-block {
                    display: flex;
        
                    > div {
                        width: 100%;
                        @media screen and (min-width > ${props => props.theme.breakpoints.lg}px) {
                            width: calc(100% - 80px);
                        };
                        
                        &.buttons {
                            flex-direction: column;
                            width: 65px;
                            justify-content: start;
                            align-items: start;
                            gap: 10px;
                            margin-top: 0;
                        }
                    }
                }

                > div {
                    > .buttons {
                        display: none;
                    }
                }
            }
        }
    }
    &.first-row {
        grid-template-columns: 1fr;
        @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
`;

interface UsersRowProps {
    users: any[] | undefined;
    rowNumber: number;
    type: string;
}

export const UsersRow: FC<UsersRowProps> = (props) => {
    const { users, rowNumber, type } = props;
    return (
           <StyledUsersRow className={type}>
            {
                <>
                {
                    users?.map(userItem => {
                        return <UserCard key={uuidv4()} user={userItem} type={type} />
                    })
                }  
                </>
            }
           </StyledUsersRow> 
        )
}
