import React, { useLayoutEffect, useState } from 'react';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, getDocs, setDoc, collection } from 'firebase/firestore';
import styled, { ThemeProvider } from 'styled-components';
import { useUserStore } from './store/userStore';
import SignUpDialog from './components/SignUpDialog';
import {blueTheme, defaultTheme} from './theme';
import Dashboard from './components/Dashboard';
import TermsAndConditions from './components/TermsAndConditions';

const StyledApp = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    background: ${props => props.theme.palette.primary.gradient};
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  min-height: 100vh;

  input,
  textarea,
  select,
  button {
    font-size: 0.9em;
  }

  p.tncLink {
    color: black;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        color: white;
    }
  }
`;

export const StyledActionBar = styled.div`
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
    padding: 1em;
    box-sizing: border-box;
    margin-top: 0;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        margin-top: 2em;
    }
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 2em;

    button {
        display: flex;
        flex-direction: column;
        background: none;
        border: none;
        color: ${props => props.theme.palette.primary.contrastText};
        fill: ${props => props.theme.palette.primary.contrastText};
        align-items: center;
        cursor: pointer;
        svg {
            width: 2em;
            height: 3em;
        }
        font-size: 0.7em;
        font-weight: 600;
        text-transform: uppercase;
    }
`;

export const MobileActionBar = styled(StyledActionBar)`
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0 0 0 0;
    background: ${props => props.theme.palette.primary.gradient};
`;

function App() {
  const { user, setUser } = useUserStore();
  const [files, setFiles] = useState<string[]>([]);
  const [tnc, setTnc] = useState<boolean>(false);

  // const getURLs = async() => {
  //   const querySnapshot = await getDocs(collection(db, 'Users'));
  //   console.log({querySnapshot});
  //   const filesCopy = files;
  //   await querySnapshot.forEach((doc) => {
  //     console.log({data: doc.data()});
  //     filesCopy.push(doc.data().url);
  //   })
  //   setFiles(filesCopy);
  // }

  // useLayoutEffect(() => {
  //   getURLs()
  // }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
    <StyledApp>
      {
        !user && !tnc &&
        <SignUpDialog />
      }
      {
        user && !tnc &&
          <Dashboard />
      }
      {
        tnc &&
          <TermsAndConditions />
      }
      <p className="tncLink" style={{cursor: 'pointer', marginBottom: '3em', fontWeight: 'bold'}} onClick={() => setTnc(!tnc)}>{!tnc ? 'Terms and Conditions' : 'back'}</p>
    </StyledApp>
    </ThemeProvider>
  );
}

export default App;
