import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonOutlined } from './Sidebar';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useUserStore } from '../store/userStore';
import { useViewStore } from '../store/viewStore';

const StyledTextArea = styled.textarea`
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

const MessageDialog: FC = (props) => {
    
    const { setSidebarView, messageRecipient } = useViewStore();

    const [ message, setMessage ] = useState<string>('');
    const [ to, setTo ] = useState<string>(messageRecipient ? messageRecipient.email ? messageRecipient.email : '' : '');
    const [ messageSent, setMessageSent ] = useState<boolean>(false);

    const { user, userDetails } = useUserStore();

    useEffect(() => {
        if (messageRecipient) {
            setTo(messageRecipient.email ? messageRecipient.email : '');
        }
    }, [messageRecipient])

    return (
        <>
            {
            !messageSent &&
            <>
                <h2 style={{width: '100%', maxWidth: 'none'}}>Message {messageRecipient && `to ${messageRecipient.name}`}</h2>
                <form style={{width: '100%', marginTop: '2em'}}>
                    <StyledTextArea autoFocus onChange={(e) => setMessage(e.target.value)} value={message} rows={20}></StyledTextArea>
                </form>
                <ButtonOutlined onClick={() => {
                    setDoc(doc(db, 'Mails', uuidv4()), {
                        to: [to],
                        from: user?.email,
                        fromName: userDetails?.name,
                        message: {
                            subject: `${userDetails?.name} is reaching out via Ambassadors Connect`,
                            text: message,
                        }
                    })
                    .then(res => {
                        setMessageSent(true);
                        setTimeout(() => {
                            setSidebarView('Profile');
                            setMessageSent(false);
                        }, 2000)
                    })
                    .catch(err => console.error(err.message));
                }}>Send</ButtonOutlined>
                </>
            }
            {
                messageSent &&
                <>
                    <h2>Message to {messageRecipient?.name} successfully sent.</h2>
                </>
            }
        </>
    )
}

export default MessageDialog;