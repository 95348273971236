import { FC, useEffect, useState } from 'react';
import { UserDetails, useUserStore } from '../store/userStore';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import styled from 'styled-components';
import SearchIcon from 'mdi-react/SearchIcon';
import { UsersRow } from './UsersRow';
import { v4 as uuidv4 } from 'uuid';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { defaultTheme } from '../theme';
import useFilteredUsers from '../hooks/useFilteredUsers';
import LoadingSpinner from './LoadingSpinner';

const StyledMainContainerContent = styled.div`
    /* overflow: hidden; */
    /* height: calc(100vh - 2em); */
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: start;

    @media screen and (max-width: ${props => props.theme.breakpoints.lg}px) {
        width: 100%;
    }
    &.user-list-map-block {
        width: 100%;
        > div {
            > div {
                grid-template-columns: 1fr;
            }
        }
    }
`;

export const SearchBlock = styled.div`
    width: 100%;
    border-radius: 25px;
    background: ${props => props.theme.palette.primary.contrastText};
    height: 75px;
    display: flex;
    overflow: hidden;
    align-items: center;

    input {
        width: calc(100% - 50px);
        border: none;
        padding: 0 10px 0 35px;
        height: 100%;

        &:focus{
            outline: none;
        }
    }
    button {
        background: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.contrastText};
        border: none;
        width: 35px;
        border-radius: 50%;
        height: 35px;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const UsersBlock = styled.div`
    width: 100%;
    display: grid;
    align-items: start;
    /* height: calc(100% - 75px); */
    margin-top: 0.5em;
    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        margin-top: 2em;
    }
    gap: 2em;
    overflow-y: auto;
    
    &:last-child() {
        gap: 0;
    }
`;

interface UserItem {
    city?: string;
    country?: string;
    experience?: number;
    languages: string[];
    markets?: string[];
    name?: string;
    position?: string;
    profilePic?: string;
}

interface MainContainerProps {
    filteredUserProps?: any[],
    isMapList?: boolean
}

const MainContainer: FC<MainContainerProps> = (props) =>  {
    const {filteredUserProps, isMapList} = props;
    const { user } = useUserStore();
    const { width } = useWindowDimensions();

    const [ filterString, setFilterString ] = useState<string>();

    const { filteredUsers: users } = useFilteredUsers(filterString);

    const userlistMap = [{
        usersRow: filteredUserProps,
        type: 'first-row'
    }];

    return (
        <>
        {
            isMapList ?
            <StyledMainContainerContent className='user-list-map-block'>
                <UsersBlock>
                    {
                        userlistMap?.map((row, index) => {
                            return <UsersRow users={row.usersRow} type={row.type} key={uuidv4()} rowNumber={index} />
                        })
                    }
                </UsersBlock>
            </StyledMainContainerContent>
            :
            <>
            {
                users && users.length > 0 &&
                <StyledMainContainerContent>
                    {
                        width > defaultTheme.breakpoints.lg &&
                        <SearchBlock>
                            <input type="text" placeholder='Type Name, Country, Language or Market to filter' onChange={(e) => setFilterString(e.target.value)} value={filterString} />
                            <button>
                                <SearchIcon />
                            </button>
                        </SearchBlock>
                    }
                    <UsersBlock>
                        {
                            (filteredUserProps || users).map((row, index) => {
                                return <UsersRow users={row.usersRow} type={row.type} key={uuidv4()} rowNumber={index} />
                            })
                        }
                    </UsersBlock>
                    
                </StyledMainContainerContent>
            }
            </>
        }
        </>
    )
}

export default MainContainer;