import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { MessageObject, UserDetails } from '../store/userStore';
import ReplyIcon from 'mdi-react/ReplyIcon';
import ShareIcon from 'mdi-react/ShareIcon';


interface MessageCardProps {
    type: string;
    message: MessageObject
}

const StyledMessageCard = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    align-items: center;
    width: 100%;
    grid-gap: 2em;
    margin-bottom: 1em;

    h4 {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: ${props => props.theme.palette.primary.main};
        margin: 0;
    }

    p {
        text-align: left !important;
    }

    .imageContainer {
        position: relative;
        img {
            max-width: 100%;
            border-radius: 50%;
            width: 60px;
            height: 60px;
        }
    }
`;

const TypeIndicator = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 8px;
    background: ${props => props.theme.palette.primary.main};
    position: absolute;
    bottom: 0.2em;
    right: -0.4em;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 80%;
    }
`;

const MessageCard: FC<MessageCardProps> = (props) => {
    const { type, message } = props;
    const [ toProfile, setToProfile ] = useState<UserDetails>();
    
    const loadProfilePic = (email: string) => {
        const q = query(collection(db, 'Users'), where('email', '==', email));
        getDocs(q).then(docs => docs.forEach(doc => setToProfile(doc.data() as UserDetails)));
    }

    loadProfilePic(message.to[0]);

    return (
        <StyledMessageCard>
            <div className='imageContainer'>
                <img src={toProfile?.profilePic || `${process.env.PUBLIC_URL}/defaultAvatar.png`} alt="profile" />
                {
                    type === 'to' &&
                        <TypeIndicator>
                            <ShareIcon />
                        </TypeIndicator>
                }
                {
                    type === 'from' &&
                        <TypeIndicator>
                            <ReplyIcon />
                        </TypeIndicator>
                }
            </div>
            <div>
                <h4>{toProfile?.name}</h4>
                <p>{message.message.text}</p>
            </div>
        </StyledMessageCard>
    )
}

export default MessageCard