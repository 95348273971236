import { collection, getDocs, doc, onSnapshot, query, where } from 'firebase/firestore';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { UserDetails, useUserStore } from '../store/userStore';
import { ReactComponent as EuropeMapSvg } from '../europemap.svg';
import { Country, useCountryStore } from '../utils/GetCountriesData';
import { UserCard } from './UserCard';
import CloseIcon from 'mdi-react/CloseIcon';
import { useViewStore } from '../store/viewStore';


interface MessageCardProps {
    
}

const StyledMapContainer = styled.div`
    width: auto;
    overflow: hidden;
    position: relative;
    svg {
        width: auto;
    }
    #map-block {
        position: relative;
        width: auto;
        overflow: auto hidden;
    }
`;

const StyledUserPic = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: .5s;
    cursor: pointer;
`;

const StyledUsersNumber = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
    background: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    text-align: center;
    line-height: 1.7;
    cursor: pointer;
`;

const StyledUserDialog = styled.div`
    position: absolute;
    top: calc(60% - 200px);
    left: calc(50% - 250px);
    padding: 1em;
    border-radius: 25px;
    min-width: 250px;
    background: ${props => props.theme.palette.primary.contrastText};
    -webkit-box-shadow: 0px 0px 22px 3px rgba(0,0,0,0.51); 
    box-shadow: 0px 0px 22px 3px rgba(0,0,0,0.51);

    .close-icon {
        position: absolute;
        right: 1em;
        top: 1em;
        color: #000000;
        width: 20px;
        cursor: pointer;
    }

    .bubbles-block {
        .buttons {
            display: none;
        }
    }
    > div {
        border: none;
        > div {
            padding: 0;
        }
    }
`;

const Map: FC<MessageCardProps> = (props) => {
    const [ countries, setCountries ] = useState<Country[]>();
    const [ countriesWithUsers, setCountriesWithUsers ] = useState<any>();
    const { setUsers } = useUserStore();
    const [ userDialog, setUserDialog ] = useState<UserDetails>();
    const { setView, setUserList, setSidebarView } = useViewStore();
    const { user } = useUserStore();
    const shuffle = (array: UserDetails[]) => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      };
    const getCountries = () => {
        fetch(`${process.env.PUBLIC_URL}/countries.json`
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then((response) => {
            
            return response.json();
          })
          .then((myJson) => {
            setCountries(myJson);
          });
    }

    const isComplete = (user: UserDetails): boolean => {
        if (user.name && user.position && user.city && user.country && user.languages && user.markets) {
            return true;
        } else {
            return false;
        }
    }
    
    const getUsers = async(str?: string) => {
        const querySnapshot = await getDocs(query(collection(db, 'Users'), where("admin", "==", false)));
        const usersLocal: any[] = [];
        await querySnapshot.forEach((doc: any) => {
            if (!doc.admin && isComplete(doc.data())) {
                usersLocal.push(doc.data());
            }
        });
        if (countries) {
            await countries.forEach((country: Country) => {
                country.users = shuffle(usersLocal.filter(item => item.country === country.label));
                if (country.users && !userDialog) {
                    setUserDialog(country.users[0]);
                }
            });
            setCountriesWithUsers(countries);
        }
        
    }

    if (!countriesWithUsers) {
        getCountries();
        getUsers();
    }

    const userClick = (user: UserDetails) => {
        setView('Map');
        setUserDialog(user);
        setShowDialog(true);
        setSidebarView('Profile');
    }

    const [showDialog, setShowDialog] = useState<boolean>(false);
    
    const showUserList = (userList: UserDetails[]) => {
        setUsers(userList);
        setShowDialog(false);
        setSidebarView('UserList');
        setUserList(userList);
    }

    return (
        <StyledMapContainer id='map-container'>
            <div id='map-block'>
                <EuropeMapSvg id='map' />
                {
                    countriesWithUsers &&
                    <>
                    {
                        countriesWithUsers.map((country: Country, index: any) => {
                            return country.users.length > country.coordinates.length ?
                                <StyledUsersNumber 
                                onClick={() => showUserList(country.users)}
                                style={
                                    {  top: country.coordinates[0].y, 
                                    left: country.coordinates[0].x 
                                    }
                                }>{country.users.length}</StyledUsersNumber>
                            :
                            country.users?.map((user: UserDetails, userId: number) => {
                                return <StyledUserPic onClick={() => userClick(user)} src={user.profilePic || `${process.env.PUBLIC_URL}/defaultAvatar.png`} 
                                        style={{
                                            top: country.coordinates[userId].y,
                                            left: country.coordinates[userId].x
                                        }} />
                            })
                        })
                    }
                    </>
                }
                {
                    showDialog &&
                    <StyledUserDialog>
                        <CloseIcon className='close-icon' onClick={() => setShowDialog(false)} />
                        <UserCard key={userDialog?.name} user={userDialog} type="first-row" />
                    </StyledUserDialog>
                }
            </div>
        </StyledMapContainer>
    )
}

export default Map