import styled from 'styled-components';
import { useViewStore } from '../store/viewStore';
import MainContainer from './MainContainer';
import Sidebar from './Sidebar';
import { defaultTheme } from '../theme';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Map from './Map';

const Grid = styled.div`
    display: grid;
    box-sizing: border-box;
    padding: 0.5em;
    width: 100vw;
    grid-template-columns: 1fr;
    align-items: flex-start;

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
        grid-template-columns: 1fr 2fr;
        padding: 2em;
    }
    gap: 2em;
`;

const Dashboard = () => {
    const { view } = useViewStore();
    const { width } = useWindowDimensions();

    return (
        
        <Grid>
            <Sidebar />
            {
                width > defaultTheme.breakpoints.lg &&
                <>
                    {
                        view === 'Map' ?
                        <Map />
                        :
                        <MainContainer />
                    }
                </>
            }
        </Grid>
    )
}

export default Dashboard;