import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  borderRadius: '31px',
  smallBorderRadius: '10px',
  breakpoints: {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    sm: 576,
    xs: 0
  },
  palette: {
    common: {
        black: '#222831',
        white: '#ffffff',
        red: '#FC3F74'
    },
    primary: {
        main: '#D546DB',
        contrastText: '#ffffff',
        gradient: 'linear-gradient(124deg, rgba(84,135,239,1) 0%, rgba(209,70,224,1) 23%, rgba(244,62,171,1) 63%, rgba(255,63,90,1) 100%)',
    },
    secondary: {
        main: 'white',
        contrastText: 'black'
    }
  }
}

export const blueTheme: DefaultTheme = {
    borderRadius: '31px',
    smallBorderRadius: '10px',
    breakpoints: {
      xxl: 1400,
      xl: 1200,
      lg: 992,
      sm: 576,
      xs: 0
    },
    palette: {
      common: {
        black: '#222831',
        white: '#ffffff',
        red: 'FC3F74'
      },
      primary: {
        main: '#D546DB',
        contrastText: '#ffffff',
        gradient: 'linear-gradient(124deg, rgba(84,135,239,1) 0%, rgba(255,255,255,1) 23%, rgba(244,62,171,1) 63%, rgba(255,63,90,1) 100%)',
      },
      secondary: {
        main: '#709fb0',
        contrastText: '#ffffff'
      }
    }
  }