import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import MessageNotificationIcon from 'mdi-react/MessageNotificationIcon';
import React, { useState, FC, useEffect } from 'react';
import styled from 'styled-components';
import { useViewStore } from '../store/viewStore';
import { v4 as uuidv4 } from 'uuid';
import EmailFastIcon from 'mdi-react/EmailFastIcon';

const StyledUserCard = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background: ${props => props.theme.palette.primary.contrastText};
    overflow: hidden;
    border: 2px solid ${props => props.theme.palette.primary.main};
    box-sizing: border-box;
    
    > div {
        padding: 1em;
        height: calc(100% - 2em);
        display: flex;
        flex-direction: column;

        .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: no-wrap;
            align-items: center;
            margin-bottom: 10px;

            img.profilePicture {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: ${props => props.theme.palette.primary.main};
                object-fit: cover;
                object-position: center center;
            }
            h2 {
                color: ${props => props.theme.palette.primary.main};
                font-size: 1em;
                margin: 0 0 0 20px;
            }
            &.experience {
                border-bottom: 2px solid ${props => props.theme.palette.primary.main};
                color: #000000;
                font-size: 0.75em;
                line-height: 2em;
                align-items: center;
                justify-content: center;
                gap: 5px;

                &.large-mode {
                    border: none;
                    justify-content: start;
                    margin: 0 0 0 20px;
                }
            }
            &.buttons {
                align-items: center;
                justify-content: center;
                gap: 1em;
                margin-top: auto;
                margin-bottom: 0;
                
                &.large-mode {
                    flex-direction: column;
                }
            }
        }
    }
`;

const Bubbles = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

const Bubble = styled.div`
    border-radius: ${props => props.theme.smallBorderRadius};
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    padding: 0.4em 1em;
    font-size: 0.75em;
    font-weight: 600;

    &.market {
        background-color: #f63f9f;
    }
`;

const ButtonOutlined = styled.button`
    cursor: pointer;
    border: none;
    background: ${props => props.theme.palette.primary.contrastText};
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    padding: 0;
    margin: 0;
    font-size: 0.75em;
`;

interface UserCardProps {
    user: any;
    type: string;
}

export const UserCard: FC<UserCardProps> = (props) => {

    const {user, type} = props;

    const { setSidebarView, setMessageRecipient } = useViewStore();

    const sendMessage = () => {
        setSidebarView('SendMessage');
        setMessageRecipient(user);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        console.log(user);
    };

    return (
           <StyledUserCard>
            <div>
                <div className='row'>
                    <div>
                        {
                            <img className="profilePicture" src={user.profilePic || `${process.env.PUBLIC_URL}/defaultAvatar.png`} alt='Profile' />
                        }
                    </div>
                    <div>
                        {
                            user.name &&
                            <h2>{user.name}</h2>
                        }
                        {
                            type !== 'first-row' &&
                            <div className='row experience large-mode'>
                            {
                                user.position &&
                                <>
                                    <div>
                                        {user.position}
                                    </div>
                                    <div>|</div>
                                </>
                            }
                            {
                                user.country &&
                                <div>
                                    {user.country}
                                </div>
                            }
                            </div>
                        }
                    </div>
                </div>
                {
                    type === 'first-row' ?
                    <div className='row experience'>
                    {
                        user.position &&
                        <>
                            <div>
                                {user.position}
                            </div>
                            <div>|</div>
                        </>
                    }
                    {
                        user.country &&
                        <div>
                            {user.country}
                        </div>
                    }
                    </div>
                    :
                    <div className='row experience'></div>
                }
                <div className='bubbles-block'>
                    <div>
                        <div className='row'>
                            {
                                user.languages &&
                                <Bubbles>
                                    {
                                        user?.languages.map((language: string) => {
                                            return <Bubble key={uuidv4()}>{language}</Bubble>
                                        })
                                    }
                                </Bubbles>
                            }
                        </div>
                        <div className='row'>
                            {
                                user.markets &&
                                <Bubbles>
                                    {
                                        user?.markets.map((market: string) => {
                                            return market && <Bubble key={uuidv4()} className='market'>{market}</Bubble>
                                        })
                                    }
                                </Bubbles>
                            }
                        </div>
                        <div className='row'>
                            {
                                user.specialSkills &&
                                <Bubbles>
                                    {
                                        user?.specialSkills.map((specialSkill: string) => {
                                            return specialSkill && <Bubble key={uuidv4()}>{specialSkill}</Bubble>
                                        })
                                    }
                                </Bubbles>
                            }
                        </div>
                    </div>
                    <div className='row buttons'>
                        {/* <ButtonOutlined onClick={() => add()}>
                            <AccountPlusIcon size={20} />
                            Add
                        </ButtonOutlined> */}
                        <ButtonOutlined onClick={() => sendMessage()}>
                            <EmailFastIcon size={20} />
                            Message
                        </ButtonOutlined>
                    </div>
                </div>
                <div className='row buttons'>
                    {/* <ButtonOutlined onClick={() => add()}>
                        <AccountPlusIcon size={20} />
                        Add
                    </ButtonOutlined> */}
                    <ButtonOutlined onClick={() => sendMessage()}>
                        <EmailFastIcon size={20} />
                        Message
                    </ButtonOutlined>
                </div>
            </div>
           </StyledUserCard> 
        )
}
